import { css } from "@emotion/react";

export {
  absolute,
  block,
  border,
  borderRound,
  cover,
  flex,
  flexCol,
  labelText,
  overflowHidden,
  mb6,
  p8,
  relative,
  w100,
} from "app/styles/utils";

export const wrap = css`
  --inset: 45px;
`;

export const card = css`
  aspect-ratio: 1 / 1;
  width: 100%;
`;

export const image = css`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

export const pinText = css`
  display: flex;
  align-items: center;
  height: 33px;
  bottom: 0;
  left: var(--inset);
`;

export const insetButton = css`
  right: var(--inset);
`;

export const mt60px = css`
  margin-top: 60px;
`;
