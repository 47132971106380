import React from "react";
import { Link } from "gatsby";
import _get from "lodash.get";

import MoreBtn from "app/components/MoreBtn";

import * as S from "app/components/CardButton.style";

const styles = {
  frameless: {
    link: [S.flex, S.flexCol, S.labelText, S.relative, S.wrap],
    imageFrame: [S.relative, S.flex, S.overflowHidden, S.card],
    text: [S.absolute, S.pinText],
    button: [S.insetButton],
    image: [S.image],
  },
  standard: {
    link: [
      S.flex,
      S.flexCol,
      S.border,
      S.borderRound,
      S.labelText,
      S.p8,
      S.relative,
      S.wrap,
    ],
    text: undefined,
    imageFrame: [S.relative, S.flex, S.mb6, S.w100, S.overflowHidden, S.card],
    button: undefined,
    image: [S.image],
  },
};

const CardButton = ({
  className,
  title,
  image,
  link,
  variant = "standard",
}) => {
  const style = _get(styles, variant) || styles.standard;

  return (
    <Link
      className={className}
      css={style.link}
      to={link}
      data-track-name="card-button"
    >
      <div css={style.imageFrame}>
        <img css={style.image} src={_get(image, "0.optmizedUrl")} alt="" />
      </div>
      <span css={style.text}>{title}</span>
      <MoreBtn
        css={style.button}
        invert={["frameless"].includes(variant)}
        pin
      />
    </Link>
  );
};

export default CardButton;
