import React from "react";
import { graphql } from "gatsby";
import _get from "lodash.get";

import TheForms from "app/components/TheForms";
import FormCards from "app/components/FormCards";
import FourWorlds from "app/components/FourWorlds";

const templates = {
  cardList: FormCards,
  fourWorlds: FourWorlds,
  theSixForms: TheForms,
};

const ExhibitionHome = (props) => {
  const homepage = _get(props, "data.entry.homepage");
  const Component = _get(templates, homepage) || FormCards;

  return <Component {...props} />;
};

export default ExhibitionHome;

export const EXHIBITION_QUERY = graphql`
  query ExhibitionHomeQuery($id: String) {
    entry: craftExhibitionsExhibitionsEntry(id: { eq: $id }) {
      id
      homepage
      explorations {
        ... on Craft_sitePage_formPage_Entry {
          id
          uri
          displayTitle
          title
          lft
          image {
            ...AssetCardImage
          }
          buttonImage: image {
            ...ButtonCardImage
          }
        }
      }
    }
  }
`;
