import { css } from "@emotion/react";
// import styled from "@emotion/styled";
import { fontWeights } from "app/styles/utils";

export { cover, flexCenterAll, relative, square } from "app/styles/utils";

export const formsGlobal = css`
  :root {
    --row-gap: 20px;
  }
`;

export const wrap = css`
  --form-box-inset: 16px;
  --form-box-size: calc(100% - var(--form-box-inset) * 2);
  --form-box-border: transparent;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column: 1 / -1;
  padding: 0;

  @media (min-width: 920px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const disabledOverlay = css`
  display: block;
  z-index: 5;
  background: var(--graydark);
  opacity: 0.75;
`;

export const formBox = css`
  @media (max-width: 919px) {
    &:nth-of-type(2n + 2) {
      --form-box-border: transparent transparent transparent var(--ivory);
    }

    &:nth-of-type(n + 3) {
      border-top: 1px solid var(--ivory);
    }
  }

  @media (min-width: 920px) {
    &:nth-of-type(3n + 2) {
      --form-box-border: transparent var(--ivory) transparent var(--ivory);
    }

    &:nth-of-type(n + 4) {
      border-top: 1px solid var(--ivory);
    }
  }
`;

export const imageBox = css`
  padding: 0 var(--form-box-inset);
  border: 1px solid transparent;
  border-color: var(--form-box-border);
  margin: var(--form-box-inset) 0;
`;

export const image = css`
  height: 100%;

  [data-placeholder-image] {
    object-fit: contain;
    object-position: 50% 50%;
  }
`;

export const moreBtn = css`
  position: absolute;
  bottom: calc(var(--form-box-inset) * 2);
  right: calc(var(--form-box-inset) * 2);
  width: calc(var(--form-box-inset) * 2);
  height: calc(var(--form-box-inset) * 2);
  background: var(--ivory);
  color: var(--graydark);
  border-radius: 100%;
`;

export const icon = css`
  width: 28px;
  height: 28px;
`;

export const labelText = css`
  display: block;
  position: absolute;
  bottom: calc(var(--form-box-inset) * 2);
  left: calc(var(--form-box-inset) * 2);
  right: calc(var(--form-box-inset) * 2);
  font-size: 20px;
  line-height: ${30 / 20};
  letter-spacing: -0.0625rem;
  font-weight: ${fontWeights.medium};
  white-space: pre-line;
`;
