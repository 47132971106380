import React, { useEffect } from "react";
import _get from "lodash.get";

import CardButton from "app/components/CardButton";

import useNavData from "app/hooks/useNav";
import { usePageContext } from "app/hooks/useSitePages";

import * as S from "app/components/FormCards.style";

const FormCard = ({ uri, buttonImage, title, displayTitle, ...props }) => {
  const { baseUri } = usePageContext();

  return (
    <CardButton
      {...props}
      link={`/${baseUri}/${uri}`}
      image={buttonImage}
      title={displayTitle || title}
    />
  );
};

const FormCards = ({ data }) => {
  const explorations = _get(data, "entry.explorations");
  const { setNavItems } = useNavData();

  useEffect(() => {
    setNavItems(undefined);
  }, [setNavItems]);

  if (explorations.length === 2) {
    return (
      <div css={S.grid2up}>
        <FormCard variant={"frameless"} {..._get(explorations, "[0]", {})} />
        <div css={S.divider} />
        <FormCard variant={"frameless"} {..._get(explorations, "[1]", {})} />
      </div>
    );
  }

  return <div css={S.grid}>{explorations.map(FormCard)}</div>;
};

export default FormCards;
