import { css } from "@emotion/react";

export {
  block,
  border,
  borderRound,
  cover,
  flex,
  flexCol,
  labelText,
  overflowHidden,
  mb6,
  p8,
  relative,
  w100,
} from "app/styles/utils";

export const grid = css`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 60px;
  margin: 120px auto;
`;

export const grid2up = css`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  grid-gap: 60px;
  width: 100%;
  margin: 120px auto;
`;

export const divider = css`
  background-color: var(--ivory);
`;
